import React, { useState, useEffect } from "react"

import MailIcon from "../../images/svg/icon_mail.svg"

import { getPlainText } from "../../utils"
import useDictionary from "../../hooks/useDictionary"

interface EmailIngredientsProps {
  heading: string
  introduction: any
  ingredients: any
  pageContext: any
}

const defaultProps = {}

export const EmailIngredients: React.FC<EmailIngredientsProps> = props => {
  if (!props.ingredients) return null

  // state
  const [pageUrl, setPageUrl] = useState("")

  // effects
  useEffect(() => {
    setPageUrl(window.location.href)
  }, [])

  // create the body content
  const body = `${props.heading}%0D%0A%0D%0A${getPlainText(
    props.introduction
  )}%0D%0A%0D%0A${getPlainText(
    props.ingredients
  )}%0D%0A%0D%0A%0D%0AFull recipe at: ${pageUrl}%0D%0A%0D%0A`

  return (
    <a
      href={`mailto:?subject=${props.heading}&body=${body}`}
      className="button email"
    >
      <MailIcon
        width="16px"
        height="16px"
        fill="#fff"
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          marginTop: -6,
          marginRight: 8,
        }}
      />
      <span>
        {useDictionary("Email Ingredients", props.pageContext.locale)}
      </span>
    </a>
  )
}

EmailIngredients.defaultProps = defaultProps

export default EmailIngredients
