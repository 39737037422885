import React, { useState, useRef, useEffect } from "react"
import Modal from "react-modal"
import { Scrollbars } from "react-custom-scrollbars-2"

import CloseIcon from "../../images/svg/icon_close.svg"
import NutritionTable from "../NutritionTable/NutritionTable"

import { NutritionFieldsModel } from "../../models/pages"
import useDictionary from "../../hooks/useDictionary"

interface RecipeNutritionModalProps {
  modalOpen: boolean
  closeModal: () => void
  nutrition?: NutritionFieldsModel
  pageContext: any
}

const defaultProps = {}

Modal.setAppElement("#___gatsby")

export const RecipeNutritionModal: React.FC<
  RecipeNutritionModalProps
> = props => {
  // state
  const [headerHeight, setHeaderHeight] = useState<number>()
  const [contentHeight, setContentHeight] = useState<number>()
  const [footerHeight, setFooterHeight] = useState<number>()

  // refs
  const headerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)

  // effects
  useEffect(() => {
    setTimeout(() => {
      if (headerRef.current) setHeaderHeight(headerRef.current.scrollHeight)
      if (contentRef.current) setContentHeight(contentRef.current.scrollHeight)
      if (footerRef.current) setFooterHeight(footerRef.current.scrollHeight)
    }, 50)
  }, [props.modalOpen])

  return (
    <Modal
      contentLabel={useDictionary(
        "Nutrition Information",
        props.pageContext.locale
      )}
      isOpen={props.modalOpen}
      className="modal__nutrition"
      onRequestClose={props.closeModal}
      closeTimeoutMS={200}
    >
      <div className="modal__header" ref={headerRef}>
        <h2>
          {useDictionary("Nutrition Information", props.pageContext.locale)}
        </h2>
      </div>
      <div className="modal__content">
        <Scrollbars
          autoHide
          style={{
            height: `min(${contentHeight}px, 80vh - ${
              (headerHeight || 0) + (footerHeight || 0)
            }px - 80px)`,
          }}
        >
          <div className="modal__scroller" ref={contentRef}>
            <NutritionTable
              nutrition={props.nutrition}
              pageContext={props.pageContext}
            />
            {JSON.stringify(props.nutrition).includes("RDI") && (
              <p>
                *{" "}
                {useDictionary(
                  "Percentage of Recommended Dietary Intake (RDI)",
                  props.pageContext.locale
                )}
              </p>
            )}
          </div>
        </Scrollbars>
      </div>
      <button className="modal__close" onClick={props.closeModal}>
        <CloseIcon width="20" height="20" />
        <span className="show-for-sr">
          {useDictionary("close", props.pageContext.locale)}
        </span>
      </button>
    </Modal>
  )
}

RecipeNutritionModal.defaultProps = defaultProps

export default RecipeNutritionModal
