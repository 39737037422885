import React from "react"

import HeroSliderControlled from "../Slider/HeroSliderControlled"

import { HeroSlide } from "../../models/common"
import Image from "../Image/Image"
import { getFirstArrayItem } from "../../utils"

interface Props {
  slides: HeroSlide[]
}

const defaultProps = {}

export const RecipeHero: React.FC<Props> = props => {
  if (!props.slides) return null
  const slides = props.slides.map(slide => {
    slide.objectFit = "cover"
    return slide
  })
  const firstSlide: HeroSlide = getFirstArrayItem(slides)

  return (
    <section className="hero">
      <div className="grid-container">
        <HeroSliderControlled slides={slides} />

        <Image
          image={firstSlide?.image}
          altText="Print hero"
          cssClass="show-for-print"
          objectPosition="left"
          objectFit="cover"
          style={{ height: 250 }}
        />
      </div>
    </section>
  )
}

RecipeHero.defaultProps = defaultProps

export default RecipeHero
