import React, { useState, useRef, useEffect } from "react"
import Slider from "react-slick"

import HeroImageSlide from "./Slides/HeroImageSlide"
import HeroVideoSlide from "./Slides/HeroVideoSlide"

import PlayIcon from "../../images/svg/icon_youTubePlay.svg"

import { HeroSlide } from "../../models/common"

interface Props {
  slides: HeroSlide[]
}

const defaultProps = {}

export const HeroSliderControlled: React.FC<Props> = props => {
  if (!props.slides) return null

  // state
  const [slider, setSlider] = useState<Slider>()
  const [sliderHelper, setSliderHelper] = useState<Slider>()

  // refs
  const sliderRef = useRef<Slider>(null)
  const sliderHelperRef = useRef<Slider>(null)

  // effects
  useEffect(() => {
    setSlider(sliderRef.current || undefined)
    setSliderHelper(sliderHelperRef.current || undefined)
  }, [])

  const sliderSettings = {
    arrows: false,
    infinite: true,
    centerMode: false,
    fade: true,
    swipe: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          centerMode: true,
          centerPadding: "15%",
          fade: false,
          swipe: true,
        },
      },
      {
        breakpoint: 639,
        settings: {
          centerMode: true,
          centerPadding: "7.5%",
          fade: false,
          swipe: true,
        },
      },
    ],
  }

  const controlSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: props.slides.length,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    focusOnSelect: true,
  }

  return (
    <div className="grid-x grid-margin-x align-center hide-for-print">
      <div className="cell large-9">
        {props.slides.length === 1 && (
          <div className="heroSlider">
            <HeroImageSlide
              {...props.slides[0]}
            />
          </div>
        )}

        {props.slides.length > 1 && (
          <Slider
            {...sliderSettings}
            className="heroSlider controlled"
            ref={sliderRef}
            asNavFor={sliderHelper}
          >
            {props.slides.map((slide, i) => {
              if (slide.video_url)
                return <HeroVideoSlide key={`slide_${i}`} {...slide} />
              if (slide?.image)
                return (
                  <HeroImageSlide
                    key={`slide_${i}`}
                    {...slide}
                  />
                )
            })}
          </Slider>
        )}
      </div>

      {props.slides.length > 1 && (
        <div className="cell large-3 show-for-large hide-for-print">
          <Slider
            {...controlSettings}
            className="heroSlider--helper"
            ref={sliderHelperRef}
            asNavFor={slider}
          >
            {props.slides.map((slide, i) => {
              if (slide.video_url)
                return <VideoMiniSlide key={`miniSlide_${i}`} {...slide} />
              if (slide?.image)
                return <HeroImageSlide key={`miniSlide_${i}`} {...slide} />
            })}
          </Slider>
        </div>
      )}
    </div>
  )
}

HeroSliderControlled.defaultProps = defaultProps

export default HeroSliderControlled

const VideoMiniSlide: React.FC<HeroSlide> = props => {
  const urlParams = new URLSearchParams(props.video_url?.split("?").pop())
  const videoId = urlParams.get("v") || props.video_url?.split("/").pop()

  return (
    <div
      className="slide__image"
      style={{
        backgroundImage: `url(http://img.youtube.com/vi/${videoId}/0.jpg)`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <PlayIcon className="yt" width="32px" height="22px" />
    </div>
  )
}
